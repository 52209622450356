import Vue from 'vue'

// axios
import axios from 'axios'
import store from '@/store/index'

const { userData } = store.state
const token = localStorage.getItem('accessToken')
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://api.arginipratamaputra.com/',
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json',
    // localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
    // Authorization: token ? token.replaceAll('"', '') : null,
    token: userData !== null ? userData.token : token,
  },
})

Vue.prototype.$http = axiosIns

export default axiosIns
