import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          // config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          // console.log('token-expired')
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            // this.refreshToken().then(r => {
            //   this.isAlreadyFetchingAccessToken = false

            //   // Update accessToken in localStorage
            //   this.setToken(r.data.token)
            //   this.setRefreshToken(r.data.refresh_token)

            //   window.location.href = ''
            //   // console.log('token-refreshed')
            //   // this.onAccessTokenFetched(r.data.accessToken)
            // })
            this.generateRefresh()
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        // if (response && response.status === 422) {
        //   // console.log('token-expired')
        //   if (!this.isAlreadyFetchingAccessToken) {
        //     this.isAlreadyFetchingAccessToken = true
        //     this.refreshToken().then(r => {
        //       this.isAlreadyFetchingAccessToken = false

        //       // Update accessToken in localStorage
        //       this.setToken(r.data.token)
        //       this.setRefreshToken(r.data.refresh_token)
        //       // this.$router.go()
        //       window.location.href = ''
        //       // console.log('token-refreshed')
        //       // this.onAccessTokenFetched(r.data.accessToken)
        //     })
        //   }
        //   const retryOriginalRequest = new Promise(resolve => {
        //     this.addSubscriber(accessToken => {
        //       // Make sure to assign accessToken according to your response.
        //       // Check: https://pixinvent.ticksy.com/ticket/2413870
        //       // Change Authorization header
        //       originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        //       resolve(this.axiosIns(originalRequest))
        //     })
        //   })
        //   return retryOriginalRequest
        // }
        return Promise.reject(error)
      },

    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    })
  }

  generateRefresh() {
    this.axiosIns.post(this.jwtConfig.refreshEndpoint)
      .then(response => {
        this.isAlreadyFetchingAccessToken = false
        this.setToken(response.data.jwt)
        this.setRefreshToken(response.data.jwt_refresh)
        localStorage.setItem('refreshToken', response.data.jwt_refresh)
        window.location.href = ''
      })
      .catch(error => {
        localStorage.removeItem(error)
        localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
        localStorage.removeItem('userData')
        localStorage.removeItem('deviceAccessToken')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      })
  }
}
