export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/Auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/Auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/Auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      pageTitle: 'Second Page',
      breadcrumb: [
        {
          text: 'Second Page',
          active: true,
        },
      ],
    },
  },

  {
    path: '/disposisi/proses-disposisi',
    name: 'proses-disposisi',
    component: () => import('@/views/pages/Disposisi/DaftarDisposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Proses Disposisi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/disposisi/selesai',
    name: 'disposisi-selesai',
    component: () => import('@/views/pages/Disposisi/DaftarDisposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Disposisi Selesai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/disposisi/tambah-disposisi',
    name: 'tambah-disposisi',
    component: () => import('@/views/pages/Disposisi/AddDisposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Tambah Disposisi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/disposisi/edit-disposisi/:id',
    name: 'edit-disposisi',
    component: () => import('@/views/pages/Disposisi/EditDisposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Edit Disposisi',
          active: true,
        },
      ],
    },
    // beforeEnter(to, from, next) {
    //   // if (JSON.parse(localStorage.getItem('permission')).find(e => e !== 'SIAP.Disposition.Level.Z') === 'SIAP.Disposition.Level.Z') {
    //   if (JSON.parse(localStorage.getItem('userData')).name === 'Staff SE') {
    //     next()
    //   } else if (JSON.parse(localStorage.getItem('userData')).name === 'Super Admin') {
    //     next()
    //   } else {
    //     next('/disposisi/proses-disposisi')
    //   }
    // },
  },
  {
    path: '/disposisi/detail-disposisi/:id',
    name: 'detail-disposisi',
    component: () => import('@/views/pages/Disposisi/EditDisposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Detail Disposisi',
          active: true,
        },
      ],
    },
  },

  {
    path: '/disposisi/perintah',
    name: 'perintah-disposisi',
    component: () => import('@/views/pages/Disposisi/Perintah.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Perintah Disposisi',
          active: true,
        },
      ],
    },
  },

  {
    path: '/pages/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },

  {
    path: '/agenda',
    name: 'agenda',
    component: () => import('@/views/pages/Agenda/Calendar.vue'),
    meta: {
      pageTitle: 'Daftar Agenda',
      breadcrumb: [
        {
          text: 'Agenda',
          active: true,
        },
      ],
    },
  },
  {
    path: '/area',
    name: 'area-list',
    component: () => import('@/views/pages/todo/area.vue'),
    meta: {
      pageTitle: 'Area Management',
      breadcrumb: [
        {
          text: 'Area List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/work',
    name: 'work',
    component: () => import('@/views/pages/Work/List.vue'),
    meta: {
      pageTitle: 'Work Management',
      breadcrumb: [
        {
          text: 'Work List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/todo',
    name: 'todo',
    component: () => import('@/views/pages/todov2/todo.vue'),
    meta: {
      pageTitle: 'Todo',
      breadcrumb: [
        {
          text: 'Todo List Today',
          active: true,
        },
      ],
    },
  },

  {
    path: '/assignment',
    name: 'assignment',
    component: () => import('@/views/pages/todov2/assignment.vue'),
    meta: {
      pageTitle: 'Todo',
      breadcrumb: [
        {
          text: 'Todo Assignment',
          active: true,
        },
      ],
    },
  },

  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/pages/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },

  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/pages/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/pages/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  {
    path: '/work-management',
    name: 'work-management',
    component: () => import('@/views/pages/todo/workManagement.vue'),
    meta: {
      pageTitle: 'Chemical',
      breadcrumb: [
        {
          text: 'List Chemical',
          active: true,
        },
      ],
    },
  },

  {
    path: '/chemical',
    name: 'chemical',
    component: () => import('@/views/pages/Equipment/List.vue'),
    meta: {
      pageTitle: 'Chemical',
      breadcrumb: [
        {
          text: 'List Chemical',
          active: true,
        },
      ],
    },
  },

  {
    path: '/equipment',
    name: 'equipment',
    component: () => import('@/views/pages/Equipment/ListItem.vue'),
    meta: {
      pageTitle: 'Equipment',
      breadcrumb: [
        {
          text: 'List Equipment',
          active: true,
        },
      ],
    },
  },

  {
    path: '/garden',
    name: 'garden',
    component: () => import('@/views/pages/Garden/List.vue'),
    meta: {
      pageTitle: 'Garden',
      breadcrumb: [
        {
          text: 'List Garden',
          active: true,
        },
      ],
    },
  },

  {
    path: '/biopori',
    name: 'biopori',
    component: () => import('@/views/pages/Biopori/List.vue'),
    meta: {
      pageTitle: 'Biopori',
      breadcrumb: [
        {
          text: 'List Biopori',
          active: true,
        },
      ],
    },
  },

  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/pages/User/List.vue'),
    meta: {
      pageTitle: 'Users Management',
      breadcrumb: [
        {
          text: 'Users Management List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/add',
    name: 'addUser',
    component: () => import('@/views/pages/User/Detail.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Add New User',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/detail',
    name: 'detailUser',
    component: () => import('@/views/pages/User/Detail.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Detail User',
          active: true,
        },
      ],
    },
  },

  {
    path: '/summary',
    name: 'summary',
    component: () => import('@/views/pages/todov2/summary.vue'),
    meta: {
      pageTitle: 'Summary',
      breadcrumb: [
        {
          text: 'Work Summary',
          active: true,
        },
      ],
    },
  },

]
